import React from 'react';
import copy from 'copy-to-clipboard';
import StorageData from '../logic/StorageData';

const storageData = new StorageData();

class NewPassword extends React.Component {

    constructor(props) {
        
        super(props);
        this.state = {
            tempClass: '',
            showInfo: ''
        }
    }
   
    onClick() {
        this.setState({tempClass:'clicked', showInfo:'clicked'}); 
        copy(this.props.newPassword);

        if (this.props.storage === true) {

            storageData.saveNew(this.props.newPassword);
        }

        setTimeout(() => {
            this.setState({tempClass:''}); 
        }, 250);
        setTimeout(() => {
            this.setState({showInfo:''}); 
        }, 500);
    }
    render() {

        return (
            <div>
                <div className={`newPassword element ${this.state.tempClass}`} onClick={() => { this.onClick()}}>
                    <div className="thePassWord">{this.props.newPassword}</div>
                    <div className="info">Länge: {this.props.newPassword.length} Zeichen</div>
                </div>
                {this.state.showInfo !== '' && <div className="info">kopiert</div>}
            </div>
          );
    }
}

export default NewPassword;