import React from 'react';
import Generator from '../logic/Generator.js';
import SelectField from './SelectField.js';
import NewPassword from './NewPassword.js';
import { AdTag } from './AdTag.js';
import { Sharer } from './Sharer';

const generator = new Generator();

const DISPLAY_FORM = 1;
const DISPLAY_RESULT = 2;

class Form extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            displayScreen: DISPLAY_FORM,
            adCounter: 1,
            minLen: "5",
            maxLen: "25",
            numberOfSegments: '3',
            separator: '-',
            useNumbers: "1",
            numbersInWord: false,
            amountWords: "1",
            generatedPasswords: []
        };

        setInterval(() => {
            this.setState({
                adCounter: this.state.adCounter + 1
            });
        }, 30000);
    }

    render() {

        return (

            <div className="screens">
                <Sharer title="Passwörter die man sich merken kann" url="https://www.passpferd.de" />
                {/* <AdTag format="horizontal" /> */}

                {this.state.displayScreen === DISPLAY_FORM && <div className="form">

                    <div className="row">
                        <SelectField
                            label={"Anzahl Segmente"}
                            name={"numberOfSegments"}
                            selectedValue={this.state.numberOfSegments}
                            onChange={this.onChange}
                            options={[
                                { value: 2, label: '2' },
                                { value: 3, label: '3' },
                                { value: 4, label: '4' }]}
                        />
                    </div>

                    <div className="row">

                        <SelectField
                            label={"Trennzeichen"}
                            name={"separator"}
                            selectedValue={this.state.separator}
                            onChange={this.onChange}
                            options={[
                                { value: '#', label: '#' },
                                { value: '-', label: '-' },
                                { value: '+', label: '+' }]}
                        />

                    </div>
                    <div className="row">
                        <SelectField
                            label={"Zahlen verwenden?"}
                            name={"useNumbers"}
                            selectedValue={this.state.useNumbers}
                            onChange={this.onChange}
                            options={[{ value: 1, label: 'Ja' }, { value: 0, label: 'Nein' }]}
                        />
                    </div>

                    <div className="ctrl">
                        <button onClick={this.clickGenerateButton}>Generieren</button>
                    </div>

                </div>}

                {this.state.displayScreen === DISPLAY_RESULT && <div>
                    {this.state.generatedPasswords.length > 0 &&
                        <div>
                            <div className="display">
                                <div className="infoBig">Ausgabe Passwort:</div>

                                {this.state.generatedPasswords.map((word, index) => {

                                    return (
                                        <NewPassword key={index} storage={true} newPassword={word} />
                                    )
                                }
                                )}

                                <div className="info">Klicken um in die Zwischenablage zu kopieren</div>
                            </div>
                        </div>}

                    <div className="ctrl">
                        <button onClick={this.clickGenerateButton}>Generieren</button>
                        <button onClick={this.backToForm}>Zum Formular</button>
                    </div>
                </div>}


                <div className="some-content">
                    <p>Sichere Passwörter sind sehr wichtig. Und natürlich gibt es Passwort Manager Softwares, um diese zu verwalten. Aber in der heutigen Zeit wird man immer damit konfrontiert sein, sich weiterhin Passwörter merken zu müsen.</p>
                    <p>Und das sind dann die wichtigen, die Master-Passwörter. Der Zugang zu einem Konto, der Schlüssel zu einem Passwort-Manager - hier kommt dann <strong>Mit wenigen Klicks ein Passwort oder mehrere Passwörter erzeugen, die man sich leicht merken kann? Mit dem Correct Horse Battery Staple Prinzip werden sehr lange Passwörter auf einmal sehr leicht erinnerbar.</strong></p>
                    <p>Mit wenigen Klicks ein Passwort oder mehrere Passwörter erzeugen, die man sich leicht merken kann? Mit dem Correct Horse Battery Staple Prinzip werden sehr lange Passwörter auf einmal sehr leicht erinnerbar.</p>
                    <p>Bleibt Sicher!</p>
                </div>
            </div>

        );
    }

    onChange = (sender, value) => {

        this.setState({
            [sender]: value
        });
    }

    backToForm = () => {

        this.setState({
            displayScreen: DISPLAY_FORM
        });
    }
    clickGenerateButton = () => {

        this.setState({
            displayScreen: DISPLAY_RESULT,
            adCounter: this.state.adCounter + 1,
            generatedPasswords: generator.getPasswordsByConfig(this.state)
        });

        if (window.ga) {
            window.ga('set', 'page', "/pwd-generate");
            window.ga('send', 'pageview');  
        }
        
    }

}

export default Form;