import * as React from 'react';
import { AdTag } from './AdTag.js';
import NewPassword from './NewPassword.js';
import StorageData from '../logic/StorageData.js';

export default class ViewPwdList extends React.Component {

    state = { list: [] };
    constructor(props) {

        super(props);

        const stored = (new StorageData()).loadAll();
        this.state = {
            list: stored
        };

        console.log(this.state);
    }


    deleteFromList = (itemToDelete) => {

        let arr = this.state.list;
        arr = arr.filter(item => item !== itemToDelete)
        this.setState({
            list: arr
        }, () => console.log(this.state));
        (new StorageData()).deleteItem(itemToDelete.item);
        (new StorageData()).save(this.state.list);
    }


    render() {

        return (
            <div className="txt">
                <AdTag format="horizontal" />

                {this.state.list.length > 0 && <div className="list">

                    {this.state.list.map((item, index) => {

                        return (
                            <div key={index} className="list-item">
                                
                                <NewPassword storage={false} newPassword={item.item} />

                                <button onClick={() => this.deleteFromList(item)}>Löschen</button>
                            </div>
                        )
                    })}
                </div> }

                <div className="some-content">
                    <p>Hier finden Sie alle Passwörter, die Sie in die Zwischenablage kopiert hatten. Gespeichert werden die Passwörter im localStorage ihres Browsers.</p>
                    <p>Sollten auch andere Zugriff auf diese Anwendung in diesem Gerät/App/Browser haben, löschen Sie bitte die Zugänge wieder.</p>
                    <p>Des weiteren wird kein Kontext zu den Passwörtern geliefert, es ist nur eine kleine Gedächtnisstütze, falls ihnen das Passwort unabsichtlich aus der Zwischenablage entfernt wurde.</p>
                </div>

                {this.state.list.length === 0 && <div>Keine Passwörter gespeichert</div>}
            </div>
        );
    }
}
    