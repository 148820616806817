
const LOCAL_STORAGE_LIST = 'storedPasswords';

class StorageData {

    saveNew(password) {

        let loadedItems = this.loadAll();
        loadedItems.push({
            item: password, date: new Date().getTime()
        });

        this.save(loadedItems);
    }

    save(items) {
        localStorage.setItem(
            LOCAL_STORAGE_LIST, 
            JSON.stringify(items)
        );
    }

    loadAll() {

        const loadedIems = JSON.parse(
            localStorage.getItem(LOCAL_STORAGE_LIST)
        );

        if(loadedIems === null) { 
            return [];
        }

        return loadedIems;
    }

    deleteItem(password) {

        const loadedIems = this.loadAll();
        let newItemsList = [];
        for (let i=0; i<loadedIems.length; i++) {

            if(loadedIems[i].item !== password) {
                newItemsList.push(loadedIems[i]);
            }
        }

        this.save(newItemsList);
    }
}
export default StorageData;