import * as React from 'react';
import { DropdownMenu, MenuItem } from 'react-bootstrap-dropdown-menu';
import { Link } from 'react-router-dom';
import logo from '../logo.svg'

class Header extends React.Component {

    render() {

        const alreadyStored = (sessionStorage.getItem('answers') !== null);
        return (
            <header className="header">

                <div className="wrapper">
                    <h1 className="App-title">
                        <div><Link to="/"><img src={logo} className="logo" alt="Logo" /></Link></div>
                        <div><Link to="/">Passwort Generator</Link></div>
                    </h1>
                    <DropdownMenu
                        position="center"
                        triggerType="icon"
                        trigger="glyphicon glyphicon-align-justify"
                    >
                        <MenuItem text="Start" location="/" />
                        <MenuItem text="Passwörter" location="/passwortliste" />
                        <MenuItem text="Datenschutz" location="/datenschutz" />
                    </DropdownMenu>
                </div>

            </header >
        );
    }
}

export default Header;