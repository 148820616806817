import * as React from 'react';


export class AdTag extends React.Component {

    render() {

        if(document.location.href.indexOf('localhost:') !== -1) {

            return (<div className="profit">AD: {this.props.format}</div>);
        }


        setTimeout(
            () => {
                window.adsbygoogle.push({});
            },
            500);

        return (
            <div className="profit full-block">
                {this.props.format === 'horizontal' &&
                    <ins 
                        className="adsbygoogle"
                        style={{'display': 'inline-block', 'width': '320px', 'height': '100px'}}
                        data-ad-client="ca-pub-7604412297405337"
                        data-ad-slot="4988834853" 
                    />}
                {this.props.format === 'auto' &&
                    <ins
                        className="adsbygoogle"
                        style={{ display: 'block' }}
                        data-ad-client="ca-pub-7604412297405337"
                        data-ad-slot="5741745024"
                        data-ad-format="if mobile, show rectangle, else show auto"
                        data-full-width-responsive="true"
                    />}
            </div>
        );
    }
}
    