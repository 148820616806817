import React from 'react';
import { RadioGroup, RadioButton } from 'react-radio-buttons';

class SelectField extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            selectedValue: null
        }
    }
    change = (value) => {

        this.props.onChange(this.props.name, value);
    }
    render() {

        return (
            <div className="select">
                <label>{this.props.label}</label>

                <RadioGroup onChange={this.change} value={'' + this.props.selectedValue} horizontal>
                    {this.props.options.map((option, index) => {

                        const value = (typeof option.value !== "undefined") ? option.value : option;
                        const label = (typeof option.label !== "undefined") ? option.label : option;
                        return (
                            <RadioButton
                                iconSize={18}
                                iconInnerSize={8}
                                rootColor={"#ffffff"}
                                pointColor={"#ffcc00"}
                                key={index}
                                value={'' + value}>{label}
                            </RadioButton>
                        )
                    }
                    )}
                </RadioGroup>

            </div>
        );
    }

    convertPlainOptions(options) {

        if (options.length === 0 || options[0].value) {
            return options;
        }

        let newOptions = [];
        for (let i = 0; i < options.length; i++) {

            newOptions.push(
                { value: options[i], label: options[i] }
            );
        }

        return newOptions;
    }
}

export default SelectField;