import React from 'react';
import Form from './components/Form';
import ViewDatenschutz from './components/ViewDatenschutz';
import ViewPwdList from './components/ViewPwdList';
import CookieNotice from './components/CookieNotice';
import TrackingPageView from './components/TrackingPageView';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import Header from './components/Header';


function App() {
  return (
    <div className="App">
      <Router>
        <CookieNotice />
        <Header />
        <div id="content">
          <Switch>
            <Route path={'/passwortliste'} component={ViewPwdList} />
            <Route path={'/datenschutz'} component={ViewDatenschutz} />
            <Route path={'/'} component={Form} />
            <Route path="*" component={Form} />
          </Switch>
          <TrackingPageView />
        </div>
      </Router>
    </div>
  );
}

export default App;
