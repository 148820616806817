import * as React from 'react';

import {
    FacebookShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    WhatsappIcon,
    EmailIcon
} from 'react-share';

export class Sharer extends React.Component {

    render() {

        const shareUrl = this.props.url;
        const title = this.props.title;
        const mailSubject = '>>>>>PassPferd.de';
        const mailBody = 'Hallo,\ndu musst unbedingt den Passwortgenerator PassPferd.de probieren!\n' +
            'Du findest ihn hier: ' + shareUrl +
            '' +
            '';

        return (
            <div className="share-wrapper">
                <div className="sharer">
                    <FacebookShareButton 
                        url={shareUrl}
                        quote={title}
                        className="Demo__some-network__share-button"
                    >
                        <FacebookIcon size={32} round="true" />
                    </FacebookShareButton>
                    <WhatsappShareButton
                        url={shareUrl}
                        title={title}
                        separator=":: "
                        className="Demo__some-network__share-button"
                    >
                        <WhatsappIcon size={32} round="true" />
                    </WhatsappShareButton>
                    <EmailShareButton
                        subject={mailSubject}
                        body={mailBody}
                        url={shareUrl}
                    >
                        <EmailIcon size={32} round="true" />
                    </EmailShareButton>
                </div>
                <div style={{clear: 'both'}} />
            </div>
        );
    }
}